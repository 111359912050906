<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // activeIndex: "1",
      // collapsed: false,
    };
  },
  mounted() {
    // if (localStorage.getItem("honeType")) {
    //   this.activeIndex = localStorage.getItem("honeType");
    // }
  },
};
</script>



<style scoped>
/* .el-footer {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  line-height: 60px;
  height: 60px;
}
.el-footer .footer-m {
  height: 25px;
  margin-left: 10%;
  font-size: 12px;
  color: #f2f3ff;
  line-height: 17px;
}
.el-footer {
  background-image: url("../assets/image/footer.png");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
} */
/* .header {
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  font-family: PingFangSC-Medium, PingFang SC;
}
.header:hover {
  color: #1e75ff;
} */
/* .el-menu-item .is-active{
  border-bottom-color: #3F8CFFFF;
} */
body > .el-container {
  margin-bottom: 40px;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #ffffffff;
}
.bg-purple {
  background: #ffffffff;
}
.bg-purple-light {
  background: #ffffffff;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #ffffffff;
}
</style>
<style type="text/css">
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #409eff;
  /* color: #303133; */
}
.el-menu-item.is-active {
  color: #252d43;
}
.el-menu-item {
  padding: 0px;
}
</style>